<template>
	<div class="list">
		<!-- <img src="../../assets/img/xintp/sybj.png" alt=""> -->
        <img src="../../assets/img/xintp/banner.jpg" alt="" style="width:100%;height:auto">
		<router-view  @getLoad="showMsg" />
	</div>
</template>

<script>
	export default {

		data() {
			return {
				active:this.$route.params.newsid
			}
		},
		methods:{
			showMsg(){
				var that = this
				that.$emit('getLoad', true);
			},
			nato(e){
				var that=this
				that.active=e
				if(e==14 || e==15){
					that.$router.push('/zszx/listz/'+e)
				}
				
				if(e==16 || e==17){
					that.$router.push('/zszx/newsz/'+e)
				}
			}
		}
	}
</script>

<style>

</style>
